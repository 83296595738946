import { useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import MainHeader from "./components/main-header";
import GlobalStyle from "./globalStyle";
import "./App.css";

function noop() {}
(() => {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
})();
const App = () => {
  const [keyword, setKeyword] = useState("");
  const [curentInputValue, setcurentInputValue] = useState("");

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleChangeUrl = () => {
    if (keyword) {
      setcurentInputValue(keyword);
    }
  };

  return (
    <>
      <GlobalStyle />
      <MainHeader
        keyword={keyword}
        handleChange={handleChange}
        handleChangeUrl={handleChangeUrl}
      />
      <SwaggerUI
        url={
          curentInputValue
            ? `${process.env.REACT_APP_SWAGGER_URL}/${curentInputValue}`
            : `${process.env.REACT_APP_SWAGGER_URL}`
        }
      />
    </>
  );
};

export default App;
