import { header, logo } from "../../assets/images";
import { Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import styled from "styled-components";
import colors from "../../vars/colors";
const Wrapper = styled.div`
  background-image: url(${header});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0px;
  height: 381px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;

  background-color: transparent;
  @media (max-width: 991px) {
    display: block;
  }
`;

const RightSection = styled.div`
  display: flex;
  margin: 0px 20px;
  justify-content: space-between;
`;
const CenterSection = styled.div`
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 49.5px;
  height: 57.4px;
  object-fit: contain;
  margin-right: 14px;
`;

const InputGroupAddonDiv = styled(InputGroupAddon)`
  display: flex;
`;
const InputGroupAddonButton = styled(Button)`
  border: 0;
  cursor: pointer;
  text-transform: capitalize;
  color: #007fc1;
  font-family: Graphik;
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff26;
  border: solid 0.8px #ffffff;
  // width: 100%;
  height: 48px;
  position: relative;

  @media (max-width: 991px) {
    margin: 1.5rem 0px 0px 0px;
  }
  @media (max-width: 580px) {
    width: 100%;
  }
  input.form-control {
    padding: 0px 0px 0px 20px !important;
  }
  .form-control {
    // height: 100%;
    min-width: 350px;
    padding: 0;
    border: none;
    font-family: Graphik;
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    // margin-top: 5px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;

    ::placeholder {
      color: #ffffff;
      opacity: 0.8; /* Firefox */
      text-align: center;
      z-index: 1;
    }
    :focus-visible {
      outline: none;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ffffff;
      opacity: 0.8;
      text-align: center;
      z-index: 1;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ffffff;
      opacity: 0.8;
      text-align: center;
      z-index: 1;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: ${colors.black};
    background-color: transparent;
    border-color: ${colors.white};
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
    color: ${colors.black} !important;
  }
  ..btn-secondary.dropdown-toggle:hover,
  .btn-secondary:hover {
    color: ${colors.black};
    background-color: transparent;
    border-color: ${colors.white};
  }
  .btn-secondary {
    color: ${colors.black};
    background-color: transparent;
    border-color: ${colors.white};
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    box-shadow: none !important;
  }
`;
const InputGroupDiv = styled(InputGroup)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Heading1 = styled.h3`
  font-family: MetropolisBold;
  font-size: 22px;
  font-weight: Normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 2.35px;
  text-align: left;
  color: #007fc1;
`;


const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const LogoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 576px) {
    display: none;
  }
`;

const CenterTextConatiner = styled.div`
  display: flex;
  // min-width: 350px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const CenterTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`;

const CenterText1 = styled.div`
  // width: 550px;
  height: 31px;
  margin: 81px 0px 3px 0px;
  object-fit: contain;
  font-family: GraphikBold;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 3.32px;
  text-align: left;
  color: #007fc1;
  text-transform: uppercase;
`;



const MainHeader = (props) => {
  const { keyword, handleChange, handleChangeUrl } = props;

  return (
    <Wrapper>
      <Section>
        <RightSection>
          <LogoBox>
            <a to="/">
              <Logo src={logo} alt="logo" />
            </a>
            <a to="/" style={{ textDecoration: "none" }}>
              <LogoText>
                <Heading1>CREATACHAIN</Heading1>
              </LogoText>
            </a>
          </LogoBox>
        </RightSection>
        <CenterSection>
          <CenterTextConatiner>
            <CenterTextWrapper>
              <CenterText1>Api Specification</CenterText1>
            </CenterTextWrapper>

            <SearchBox>
              <InputGroupDiv>
                <Input
                  placeholder="rpc.main.node.creatachain.com"
                  type="text"
                  value={keyword}
                  name="Txs"
                  onChange={handleChange}
                />

                <InputGroupAddonDiv
                  addonType="append"
                  onClick={handleChangeUrl}
                >
                  <InputGroupAddonButton color="primary">
                    Change
                  </InputGroupAddonButton>
                </InputGroupAddonDiv>
              </InputGroupDiv>
            </SearchBox>
          </CenterTextConatiner>
        </CenterSection>
      </Section>
    </Wrapper>
  );
};

export default MainHeader;
