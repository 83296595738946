const colors = {
  background: '#F3F3F3',
  white: '#FFFFFF',
  lightGray: '#EEEEEE',
  lighterGray: '#D4D4D4',
  darkGray: '#585858',
  darkerGray: '#404040',
  green: '#65B042',
  darkerGreen: '#549237',
  mediumGray: '#828282',
  black: '#252429',
  black80Alpha2: 'rgba(37, 36, 41, 0.8)',
  disabledGray: '#BCBCBD',
  disabledGray10Alpha: 'rgba(188, 188, 189, 0.1)',
  primary: '#40b1be',
  darkerPrimary: '#3698a3',
  blue: '#1776E3',
  darkerBlue: '#0E4F9A',
  orange: '#EC5C3D',
  realBlack: '#000000',
  black10Alpha: '#545b62',
  black02Alpha: 'rgba(0, 0, 0, 0.09)',
  darkGray50Alpha: 'rgba(64, 64, 64, 0.5)',
  darkGray10Alpha: 'rgba(64, 64, 64, 0.1)',
  dark75Alpha: 'rgba(37, 36, 41, 0.75)',
  navLinkGrey: '#CFCFCF',
  red: '#8b0000',
  dMBlack1: '#323031',
  dmBlack2: '#353439',
  shaddow: '0 0.75rem 1.5rem rgb(18 38 63 / 10%)',
  borderGrey: 'rgba(90, 90, 90, 0.5)',
  chipColor: '#4044be42'
};

export default colors;
