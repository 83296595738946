import { createGlobalStyle } from 'styled-components';
import srcReg from '../src/assets/fonts/Poppins-Regular.ttf';
import srcBold from '../src/assets/fonts/Poppins-Bold.ttf';
import srcLight from '../src/assets/fonts/Poppins-Light.ttf';
import srcMed from '../src/assets/fonts/Poppins-Medium.ttf';
import MetropolisLight from '../src/assets/fonts/Metropolis-Light.woff';
import MetropolisRegular from '../src/assets/fonts/Metropolis-Regular.woff';
import MetropolisMedium from '../src/assets/fonts/Metropolis-Medium.woff';
import MetropolisBold from '../src/assets/fonts/Metropolis-Bold.woff';
import MetropolisExtraBold from '../src/assets/fonts/Metropolis-ExtraBold.woff';
import GraphikRegular from '../src/assets/fonts/GraphikRegular.otf';
import GraphikLight from '../src/assets/fonts/GraphikLight.otf';
import GraphikExtralight from '../src/assets/fonts/GraphikExtralight.otf';
import GraphikMedium from '../src/assets/fonts/GraphikMedium.otf';
import GraphikBold from '../src/assets/fonts/GraphikBold.otf';



const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: GraphikExtralight;
      src: url(${GraphikExtralight});
      font-weight:200;
    }

    @font-face {
      font-family: GraphikLight;
      src: url(${GraphikLight});
      font-weight:300;
    }

    @font-face {
      font-family: Graphik;
      src: url(${GraphikRegular});
      font-weight:400;
    }

    @font-face {
      font-family: GraphikMedium;
      src: url(${GraphikMedium});
      font-weight:500;
    }

    @font-face {
      font-family: GraphikBold;
      src: url(${GraphikBold});
      font-weight:500;
    }
    
    @font-face {
      font-family: PoppinsRegular;
      src: url(${srcReg});
      font-weight:400;
    }
    
    @font-face {
      font-family: PoppinsBold;
      src: url(${srcBold});
      font-weight: 400;
    }

    @font-face {
      font-family: PoppinsLight;
      src: url(${srcLight});
      font-weight: 400;
    }

    @font-face {
      font-family: PoppinsMedium;
      src: url(${srcMed});
      font-weight: 400;
    }
    @font-face {
      font-family: MetropolisLight;
      font-style: normal;
      font-weight: normal;
     src: url(${MetropolisLight}) ;
      }
    @font-face {
      font-family: MetropolisRegular;
      font-style: normal;
      font-weight: normal;
     src: url(${MetropolisRegular}) ;
      }
      
      @font-face {
        font-family: MetropolisMedium;
        font-style: normal;
        font-weight: normal;
       src: url(${MetropolisMedium}) ;
        }
        @font-face {
          font-family: MetropolisBold;
          font-style: normal;
          font-weight: normal;
         src: url(${MetropolisBold}) ;
          }
          
          @font-face {
            font-family: MetropolisExtraBold;
            font-style: normal;
            font-weight: normal;
           src: url(${MetropolisExtraBold}) ;
            }
    html, body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      
    }
    
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    main, menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      // font-family: Poppins, sans-serif;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, main, menu, nav, section {
      display: block;
    }

    *[hidden] {
      display: none;
    }

    // body {
    //   line-height: 1;
    // }

    ol, ul {
      // list-style: none;
    }
    
    input {
      box-sizing: border-box;
      font-family: Poppins, sans-serif;
      font-weight: normal;
    }

    a {
      cursor:pointer;
    }
`;

export default GlobalStyle;
